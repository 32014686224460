import mapIcon_Default from "~/assets/mapIcons/_mapIcon_Default.png";
import mapIcon_304FBC from "~/assets/mapIcons/mapIcon_304FBC.png";
import mapIcon_55ACEE from "~/assets/mapIcons/mapIcon_55ACEE.png";
import mapIcon_CD157D from "~/assets/mapIcons/mapIcon_CD157D.png";
import mapIcon_F68C28 from "~/assets/mapIcons/mapIcon_F68C28.png";
import mapIcon_F7E733 from "~/assets/mapIcons/mapIcon_F7E733.png";

export const
  mapIcons = {
    "DEFAULT": mapIcon_Default,
    "#304FBC": mapIcon_304FBC,
    "#55ACEE": mapIcon_55ACEE,
    "#CD157D": mapIcon_CD157D,
    "#F68C28": mapIcon_F68C28,
    "#F7E733": mapIcon_F7E733,
  },
  FILTER_INCLUDE_ALL_KEY = 'All',
  DEFAULT_FILTERS = {
    selectedName: '',
    selectedTown: FILTER_INCLUDE_ALL_KEY,
    selectedIndustry: FILTER_INCLUDE_ALL_KEY
  }
