import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import { DEFAULT_FILTERS } from '~/utils/constants'
import useResultsContent from '~/utils/useResultsContent'
import useMapContent from '~/utils/useMapContent'
import { FilteredMap } from '~/components/organisms'
import { FindingsIntro, ReportMetric, LoaderRow } from '~/components/molecules'

import './report-page.scss'
import { isEmpty } from 'lodash'

const
  outerVariants = {
    hidden: { y: '-100vh', opacity: 0 },
    visible: { y: 0, opacity: 1 }
  },
  outerTransition = {
    type: "spring",
    duration: 1,
    bounce: 0
  },
  ReportRow = ({ children, marginBottom }) => (
    <Row
      className="justify-content-center"
      style={{
        marginRight: '15px',
        marginLeft: '15px',
        ...(marginBottom ? { marginBottom } : {})
      }}>
      {children}
    </Row>
  ),
  ReportPage = () => {
    const
      {
        isLoaded: areResultsLoaded,
        errorMessage: resultsErrorMessage,
        results,
        text
      } = useResultsContent(),
      {
        isLoaded: isMapLoaded,
        errorMessage: mapErrorMessage,
        industryColors,
        points 
       } = useMapContent(),
      middleMetricLayout = [
        ['Mid Row 1', '80px'],
        ['Mid Row 2', '80px'],
        ['Mid Row 3', '70px'],
        ['Mid Row 4', '150px'],
        ['Mid Row 5', '70px'],
        ['Mid Row 6', '70px'],
        ['Mid Row 7'],
        ['Mid Row 8'],
        ['Mid Row 9', '150px'],
        ['Bottom Row 1', '100px'],
        ['Bottom Row 2', '150px']
      ]

    if (areResultsLoaded && (resultsErrorMessage || isEmpty(results) || isEmpty(text))) {
      return (
        <motion.div key="fulldatareport-error" initial="hidden" animate="visible" exit="hidden" variants={outerVariants} transition={outerTransition}>
          <Container fluid id="report-page">
            <Row className="justify-content-center" style={{ margin: '20px', fontWeight: '500', fontSize: '25px', color: '#CD157D' }}>
              {resultsErrorMessage ?? 'Unable to load content'}
            </Row>
          </Container>
        </motion.div>
      )
    }

    if (!areResultsLoaded) {
      return (
        <motion.div key="fulldatareport-loading" initial="hidden" animate="visible" exit="hidden" variants={outerVariants} transition={outerTransition}>
          <Container fluid id="report-page">
            <LoaderRow text="Loading results..." />
          </Container>
        </motion.div>
      )
    }

    return (
      <motion.div key="fulldatareport" initial="hidden" animate="visible" exit="hidden" variants={outerVariants} transition={outerTransition}>
        <Container fluid id="report-page">
          <FindingsIntro text={text} />

          <ReportRow marginBottom="80px">
            {results.filter(o => o.location === 'Top Group').map(result => (
              <ReportMetric key={result.id} {...result} />
            ))}
          </ReportRow>

          <FilteredMap
            className="report-page__map"
            activeFilters={DEFAULT_FILTERS}
            isLoaded={isMapLoaded}
            errorMessage={mapErrorMessage}
            {...{ industryColors, points }}>
            <map-footer-small key="mapfootersmall">
              <Link to="/map" className="map-page-link">EXPLORE THE MAP<div className="arrow-right"></div></Link>
            </map-footer-small>
          </FilteredMap>

          {middleMetricLayout.map(([location, marginBottom]) => (
            <ReportRow key={location} marginBottom={marginBottom}>
              {results.filter(o => o.location === location).map(result => (
                <ReportMetric key={result.id} {...result} />
              ))}
            </ReportRow>
          ))}

        </Container>
      </motion.div>
      )
    }

export default ReportPage
