import * as React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import './main-nav.scss'

const 
  activeStyle = {
    color: '#CD157D',
    textDecoration: 'underline'
  },
  menuItems = [
    { path: '/', label: 'WELCOME' },
    { path: '/full-data-report', label: 'EXPLORE RESULTS' },
    { path: '/map', label: 'EXPLORE THE MAP' },
    { path: '/faq', label: 'F.A.Q.' }
  ],
  MainNav = ({ location }) => {
    const
      [isOpen, setIsOpen] = React.useState(false),
      toggleNav = () => setIsOpen(!isOpen),
      currentPath = location.pathname,
      showBigLogo = currentPath === '/'

    return <Navbar fixed="top" className="header">
      <Navbar.Brand className={showBigLogo ? "logo big" : "logo"} href="/" />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav className="ml-auto wideNav">
          {menuItems.map(({ path, label }, i) => [
            i === 0
              ? null
              : <Navbar.Text key={`divider-${i}`}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Navbar.Text>,
            <Link
              key={`link-${i}`}
              to={path}
              className="nav-link"
              style={path===currentPath ? activeStyle : undefined}>
              {label}
            </Link>
          ])}
        </Nav>
        <motion.div
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          onClick={toggleNav}
          className="mobileNav">
          <Navbar.Collapse id="navbar-dark-example">
            <Nav className="ml-auto">
              <NavDropdown
                id="nav-dropdown-dark-example"
                alignRight
                bsPrefix="mobileNavDropdown"
              >
                <motion.div variants={{
                  open: { scaleY: 1 },
                  closed: { scaleY: 0 }
                }}>
                  {menuItems.map(({ path, label }, i) => [
                    i === 0
                      ? null
                      : <NavDropdown.Divider key={`divider-${i}`} />,
                    <NavDropdown.Item key={`item-${i}`}>
                      <Link to={path} style={path===currentPath ? activeStyle : undefined} className="nav-link">
                        {label}
                      </Link>
                    </NavDropdown.Item>
                  ])}
                </motion.div>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </motion.div>
    </Navbar>
  }

export default MainNav
