import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { DEFAULT_FILTERS } from '~/utils/constants'
import useTextContent from '~/utils/useTextContent'
import useMapContent from '~/utils/useMapContent'
import {
  MapFilter,
  FindingsIntro,
  LoaderRow
}from '~/components/molecules'
import { FilteredMap } from '~/components/organisms'
import './map-page.scss'
import { isEmpty } from 'lodash'

const
  variants = {
    hidden: { y: '-100vh', opacity: 0 },
    visible: { y: 0, opacity: 1 }
  },
  transition = {
    type: "spring",
    duration: 1,
    bounce: 0
  }

export const MapPage = () => {
  const
    {
      isLoaded: isTextLoaded,
      errorMessage: textErrorMessage,
      text
    } = useTextContent(),
    {
      isLoaded: isMapLoaded,
      errorMessage: mapErrorMessage,
      industries,
      towns,
      industryColors,
      points 
     } = useMapContent(),
    [selectedName, setSelectedName] = React.useState(DEFAULT_FILTERS.selectedName),
    [selectedTown, setSelectedTown] = React.useState(DEFAULT_FILTERS.selectedTown),
    [selectedIndustry, setSelectedIndustry] = React.useState(DEFAULT_FILTERS.selectedIndustry),
    filters = { selectedName, selectedTown, selectedIndustry },
    isLoadingMap = !(isMapLoaded && !mapErrorMessage)

  if (isTextLoaded && isEmpty(text)) {
    return (
      <motion.div key="map-page-error" initial="hidden" animate="visible" exit="hidden" variants={variants} transition={transition}>
        <Container fluid id="report-page">
          <Row className="justify-content-center" style={{ margin: '20px', fontWeight: '500', fontSize: '25px', color: '#CD157D' }}>
            {textErrorMessage ?? 'Unable to load content'}
          </Row>
        </Container>
      </motion.div>
    )
  }

  if (!isTextLoaded) {
    return (
      <motion.div key="map-page-loading" initial="hidden" animate="visible" exit="hidden" variants={variants} transition={transition}>
        <Container fluid id="report-page">
          <LoaderRow text="Loading map..." />
        </Container>
      </motion.div>
    )
  }

  return (
    <motion.div initial="hidden" animate="visible" exit="hidden" variants={variants} transition={transition}>
      <Container fluid id="map-page">
        <FindingsIntro text={text} />
        <Row id="map-page__row-a">
          <Col
            className="map-page__filters"
            sm="12"
            md="12"
            lg="10"
            xl="10">
            <div className="map-page__filter-row">
              <div className="filter-label">Filter by Business Type
                <MapFilter 
                  filterName="Industry"
                  options={industries ?? []}
                  setOption={setSelectedIndustry}
                  selected={selectedIndustry}
                  isLoading={isLoadingMap}
                />
              </div>
              <div className="filter-label">Filter by Town
                <MapFilter
                  filterName="Town"
                  options={towns ?? []}
                  setOption={setSelectedTown}
                  selected={selectedTown}
                  isLoading={isLoadingMap}
                />
              </div>
            </div>
            <div className="map-page__filter-row">
              <div className="filter-label">Find a Business
                <input
                  className="map-page__search-input"
                  placeholder={
                    isLoadingMap
                      ? 'Loading filters...'
                      : 'Type name here to search'
                  }
                  onChange={e => setSelectedName(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <FilteredMap
          className="map-page__map"
          activeFilters={filters}
          isLoaded={isMapLoaded}
          errorMessage={mapErrorMessage}
          {...{ industryColors, points }}>
          <div className='map-page__footer'>
            {text.mapFooter ?? '...'}
          </div>
        </FilteredMap>
      </Container>
    </motion.div>
  )
}

export default MapPage
