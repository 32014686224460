import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const 
  monospaceStyle = {
    fontSize: '0.8em',
    lineHeight: '2',
    marginLeft: '10px'
  },
  NotFound = () => {
    let location = useLocation()

    return (
      <Container fluid style={{'marginTop':'10px'}}>
        <Row className="justify-content-center" style={{
          'marginRight': '15px',
          'marginLeft': '15px',
          'marginTop':'25px',
          'marginBottom':'25px',
          'justifyContent':'center'
        }}>
          <Col style={{'justifyContent':'center'}}>   
            <Row
              className="justify-content-center"
              style={{
                margin: '20px',
                fontWeight: '500',
                fontSize: '25px'
              }}>
              No page found at <code style={monospaceStyle}>{location.pathname}</code>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }

export default NotFound
