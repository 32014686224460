import React from 'react'
import { motion } from 'framer-motion'
import { Container, Row } from 'react-bootstrap'
import {
  scaleUpPulse,
  scaleUp,
  rot1,
  rot2,
  swing1,
  swing2,
  swing3,
  swing4
} from '~/utils/animationVariants'

import './metrics-widgets.scss'

const 
  EarnedAnnuallyMetric = ({ totalEarnedAnnually, averageEarnedAnnuallyPerBusiness, numCompanies }) => {
    return (
      <section-4 key="section4">
        <div className="container">
          <motion.div
            initial="rest"
            whileHover="hover" whileTap="hover">
            <motion.div className="character" variants={scaleUpPulse}></motion.div>
            <div className="white-box"></div>
            <div className="yellow-box1"></div>
            <div className="yellow-box2"></div>
            <motion.div className="arrows" variants={swing2}>
              <div className="arrow-left1"></div>
              <div className="arrow-left2"></div>
            </motion.div>
            <motion.p className="number" variants={scaleUp}>
              ${totalEarnedAnnually ?? '...'}
            </motion.p>

            <div className="text-labels">
              <motion.div className="top" variants={rot2}>EARNED ANNUALLY</motion.div>
              <motion.div className="arrow-with-text" variants={rot1}>
                <motion.div className="arrow-right" variants={swing1}></motion.div>
                <div className="mid">By women owned businesses*.</div>
              </motion.div>
              <motion.div className="bot" variants={rot2}>
                *On average, each business earns{' '}
                <span style={{'color':'#F68C28'}}>
                  ${averageEarnedAnnuallyPerBusiness ?? '...'}
                </span>{' '}
                annually. This is based on the{' '}
                <span style={{'color':'#F68C28'}}>
                  {numCompanies ?? '...'}
                </span>{' '}
                businesses who have submitted their revenue.
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section-4>
    )
  },
  JobsCreatedMetric = ({ totalJobsCreated, averagePerBusiness }) => {
    return (
      <section-5 key="section5">
      <div className="container">
        <motion.div
          initial="rest"
          whileHover="hover" whileTap="hover">
          <motion.div className="character" variants={scaleUpPulse}></motion.div>
          <div className="white-box"></div>
          <div className="yellow-box1"></div>
          <div className="yellow-box2"></div>
          <motion.div className="arrows" variants={swing2}>
            <div className="arrow-left1"></div>
            <div className="arrow-left2"></div>
          </motion.div>
          <motion.p className="number" variants={scaleUp}>{totalJobsCreated ?? '...'}</motion.p>

          <div className="text-labels">
            <motion.div className="top" variants={rot1}>PART-TIME AND FULL-TIME JOBS CREATED</motion.div>
            <motion.div className="arrow-with-text" variants={rot2}>
              <motion.div className="arrow-right" variants={swing1}></motion.div>
              <div className="mid">By women owned businesses*.</div>
            </motion.div>
            <motion.div className="bot" variants={rot1}>*On average, that's <span style={{'color':' #CD157D'}}>{averagePerBusiness ?? '...'}</span> jobs created per business.</motion.div>
          </div>
        </motion.div>
        </div>
      </section-5>
    )
  },
  SerialEntrepreneursMetric = ({ totalSerialEntrepreneurs, topLabel, bottomLabel }) => (
    <section-6 key="section6">
      <div className="container">
        <motion.div
          initial="rest"
          whileHover="hover" whileTap="hover">
          <motion.div className="character" variants={scaleUpPulse}></motion.div>
          <div className="numberContainer">
            <motion.p className="number" variants={scaleUp}>{totalSerialEntrepreneurs ?? '...'}</motion.p>
          </div>
          <div className="yellow-box"></div>
          <div className="orange-box"></div>

          <motion.div className="arrows" variants={swing2}>
            <div className="arrow-left1"></div>
            <div className="arrow-left2"></div>
          </motion.div>
          <div className="text-labels">
              <motion.div className="top" variants={rot1}>{topLabel}</motion.div>
              <motion.div className="bot" variants={rot2}>{bottomLabel}</motion.div>
            </div>
        </motion.div>
      </div>
    </section-6>
  ),
  BusinessAgeMetric = ({ averageAgeOfBusiness, topLabel }) => (
    <section-7 key="section7">
      <div className="container">
        <motion.div
          initial="rest"
          whileHover="hover" whileTap="hover">
          <motion.div className="top-text" variants={rot2}>{topLabel}</motion.div>
          <motion.div className="character" variants={scaleUpPulse}></motion.div>
          <motion.p className="number" variants={scaleUp}>{averageAgeOfBusiness ?? '...'}</motion.p>
          <motion.p className="orange-box" variants={rot1}>YEARS</motion.p>
          <div className="yellow-box"></div>

          <motion.div className="arrows" variants={swing4}>
            <div className="arrow-up1"></div>
            <div className="arrow-up2"></div>
          </motion.div>
        </motion.div>
      </div>
    </section-7>
  ),
  AnnualContributionsMetric = ({ donatedAnnually, donatedPerBusiness, topLabel, bottomLabel }) => {
    return (
      <section-8 key="section8">
        <div className="container">
          <motion.div
            initial="rest"
            whileHover="hover" whileTap="hover">
            <motion.div className="character" variants={scaleUpPulse}></motion.div>
            <motion.p className="number" variants={scaleUp}>${donatedAnnually}</motion.p>
            <div className="white-box"></div>
            <div className="yellow-box1"></div>
            <div className="yellow-box2"></div>

            <motion.div className="arrows" variants={swing1}>
              <div className="arrow-left1"></div>
              <div className="arrow-left2"></div>
            </motion.div>
            <div className="text-labels">
              <motion.div className="top" variants={rot2}>{topLabel}</motion.div>
              <motion.div className="arrow-with-text" variants={rot1}>
                <motion.div className="arrow-right" variants={swing2}></motion.div>
                <div className="mid">{bottomLabel}</div>
              </motion.div>
              <motion.div className="bot" variants={rot2}>*That's an average of <span style={{'color':'#F68C28'}}>${donatedPerBusiness}</span> donated per business.</motion.div>
            </div>
          </motion.div>
        </div>
      </section-8>
    )
  },
  AnnualContributorsMetric = ({ percentContributing, topLabel, bottomLabel }) => (
    <section-9 key="section9">
      <div className="container">
        <motion.div
          initial="rest"
          whileHover="hover" whileTap="hover">
          <motion.div className="character" variants={scaleUpPulse}></motion.div>
          <motion.p className="number" variants={scaleUp}>{percentContributing ?? '...'}%</motion.p>
          <div className="white-box"></div>
          <div className="yellow-box1"></div>
          <div className="yellow-box2"></div>

          <motion.div className="arrows" variants={swing4}>
            <div className="arrow-left1"></div>
            <div className="arrow-left2"></div>
          </motion.div>
          <div className="text-labels">
            <motion.div className="top" variants={rot2}>{topLabel}</motion.div>
            <motion.div className="arrow-with-text" variants={rot1}>
              <motion.div className="arrow-right" variants={swing1}></motion.div>
              <div className="mid">
                {bottomLabel}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section-9>
  ),
  ReadyToMentorMetric = ({ numberReadyToMentor, topLabel, bottomLabel }) => (
    <section-10 key="section10">
      <div className="container">
        <motion.div
          initial="rest"
          whileHover="hover" whileTap="hover">
          <motion.div className="character" variants={scaleUpPulse}></motion.div>
          <motion.p className="number" variants={scaleUp}>{numberReadyToMentor ?? '...'}%</motion.p>
          <div className="white-box"></div>
          <div className="yellow-box1"></div>
          <div className="yellow-box2"></div>

          <motion.div className="arrowsup" variants={swing3}>
            <div className="arrow-up1"></div>
            <div className="arrow-up2"></div>
          </motion.div>
          <motion.div className="arrowsleft" variants={swing1}>
            <div className="arrow-left1"></div>
            <div className="arrow-left2"></div>
          </motion.div>
          <motion.div className="left" variants={rot2}>{topLabel}</motion.div>
          <motion.div className="right" variants={rot1}>{bottomLabel}</motion.div>
        </motion.div>
      </div>
    </section-10>
  ),
  OwnerIdentitiesMetric = ({ metricTitle, responseBreakdown }) => (
    <owner-identities-1 key="graphiccluster1">
      <motion.div className="title-box" initial="rest" whileHover="hover" whileTap="hover">
        <div className="yellow-box1"></div>
        <div className="yellow-box2"></div>
        <motion.div className="title-text" variants={scaleUp}>{metricTitle ?? 'HOW ELSE DO WOMEN BUSINESS OWNERS IDENTIFY?'}</motion.div>
      </motion.div>
      <div className="character"></div>
      <div className="yellow-box-3"></div>
      <div className="yellow-box-4"></div>

      <Container fluid className="container">
        <Row className="statistics">
          {[0, 1, 2, 3, 4].map((i) => (
            <motion.div key={i} className={`number-with-label-${i + 1}`} initial="rest" whileHover="hover" whileTap="hover">
              <motion.div className={`box-${i + 1}`} variants={rot1}>
                {responseBreakdown?.[i]?.count ?? ''}
              </motion.div>
              <motion.div className="text" variants={rot2}>
                {responseBreakdown?.[i]?.quote ?? ''}
              </motion.div>
            </motion.div>
          ))}
        </Row>
      </Container>
    </owner-identities-1>
  ),
  SectionHeading = ({ title }) => (
    <div className="section-heading">
      <motion.div className="section-heading__inner" initial="rest" whileHover="hover" whileTap="hover">
        <div className="section-heading__decoration--1" />
        <div className="section-heading__decoration--2" />
        <motion.div className="section-heading__text" variants={scaleUp}>
          {title}
        </motion.div>
      </motion.div>
    </div>
  )

export {
  EarnedAnnuallyMetric,
  JobsCreatedMetric,
  SerialEntrepreneursMetric,
  BusinessAgeMetric,
  AnnualContributionsMetric,
  AnnualContributorsMetric,
  ReadyToMentorMetric,
  OwnerIdentitiesMetric,
  SectionHeading
}
