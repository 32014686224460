import React from 'react'
import { times } from 'lodash'
import { motion } from 'framer-motion'
import { scaleUpPulse, scaleUp, rot1, rot2 } from '~/utils/animationVariants'
import './simple-metric.scss'

const
  animationVariants = {
    rest: { scale: 0 },
    visible: (entranceOrderIndex) => { // child number passed in to calculate the entrance delay
      return {
        scale: 1,
        transition: {
          delay: (entranceOrderIndex * 0.4) + 0.5,
          duration: 0.4,
          type: 'spring',
          bounce: 0.4
        }
      }
    }
  },
  SimpleMetric = ({ id, className, number, topLabel, bottomLabel, entranceOrderIndex, numberOfDecorations }) => {
    return(
      <div id={id} className={`simple-metric ${className}`}>
        <motion.div
          className="simple-metric__container"
          custom={entranceOrderIndex}
          variants={animationVariants}
          initial="rest"
          animate="visible"
          whileHover="hover"
          whileTap="hover">
          <motion.div className="simple-metric__character" variants={scaleUpPulse}></motion.div>
          <motion.p className="simple-metric__number" variants={scaleUp}>{number ?? '...'}</motion.p>
          {times(
            numberOfDecorations,
            n => <div key={n} className={`simple-metric__decoration--${n + 1}`} />
          )}
          <div className="simple-metric__text">
            <motion.div className="simple-metric__text-row" variants={rot1}>{topLabel}</motion.div>
            <motion.div className="simple-metric__text-row" variants={rot2}>{bottomLabel}</motion.div>
          </div>
        </motion.div>
      </div>
    )
  }

export default SimpleMetric
