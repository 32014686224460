import React from 'react'

export const
  GMLoadingElement = () => <div style={{
    height: `600px`,
    background:'#CDCDCD'
  }} />,
  GMContainerElement = () => <div style={{ height: `600px`}} />,
  GMMapElement = () => <div style={{ height: `100%`}} />,
  GMErrorElement = ({errorMessage}) => <div style={{
    height: `600px`,
    lineHeight: '600px',
    background:'#CDCDCD',
    textAlign: 'center',
    fontSize: '2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#CD157D'
  }}>
    {errorMessage}
  </div>
