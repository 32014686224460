import React from 'react'
import { isEmpty, times } from 'lodash'
import { motion } from 'framer-motion'
import { scaleUp, swing3, swing4, rot1, rot2 } from '~/utils/animationVariants'

import './top-values-with-quote.scss'

const
  TopValuesWithQuote = ({ className, metricTitle, responseBreakdown, topLabel, bottomLabel, numberOfColumns, numberOfRows, numExtraDecorations }) => (
    <div className={`top-values ${className}`}>
      <motion.div
        className="top-values__title"
        initial="rest"
        whileHover="hover"
        whileTap="hover">
        <div className="top-values__title-decoration--1" />
        <div className="top-values__title-decoration--2" />
        <motion.div
          className="top-values__title-text"
          variants={scaleUp}>
          {metricTitle}
        </motion.div>
      </motion.div>
      <div className="top-values__body container">
        <div className="top-values__character--1"></div>
        <div className="top-values__character--2"></div>
        {numExtraDecorations
          ? times(numExtraDecorations).map((_, i) => <div key={i} className={`top-values__body-decoration--${i+1}`} />)
          : null}
        {times(numberOfColumns ?? 1).map((_, columnIndex) => (
          <div key={columnIndex} className="top-values__value-column">
            {times(numberOfRows ?? 3).map((_, rowIndex) => {
              const
                i = rowIndex + (columnIndex * (numberOfRows ?? 3)),
                entry = responseBreakdown?.[i],
                hasPercentage = !isEmpty(entry?.percentage),
                value = hasPercentage ? entry?.percentage : entry?.count

              return (
                <motion.div
                  key={i}
                  className={`top-values__value top-values__value--${i + 1}`}
                  initial="rest"
                  whileHover="hover"
                  whileTap="hover">
                  <motion.div
                    className={`top-values__value-box`}
                    variants={scaleUp}>
                    <motion.div
                      className={`top-values__value-arrow`}
                      variants={scaleUp}
                    />
                    <p className="top-values__value-text">
                      {value ?? ''}
                      {hasPercentage ? '%' : ''}
                    </p>
                  </motion.div>
                  <div className="top-values__value-text-outer">
                    <motion.div
                      className="top-values__quotemark top-values__quotemark--opening"
                      variants={swing3}>
                      “
                    </motion.div>
                    <p className="top-values__value-text-inner">
                      {entry?.quote ?? ''}
                    </p>
                    <motion.div
                      className="top-values__quotemark top-values__quotemark--closing"
                      variants={swing4}>
                      “
                    </motion.div>
                  </div>
                </motion.div>
              )
            })}
          </div>
        ))}
        {!isEmpty(topLabel)
          ? <motion.div
              className="top-values__quote"
              initial="rest"
              whileHover="hover"
              whileTap="hover">
              <div className="top-values__quote-decoration--1" />
              <motion.div className="top-values__quote-backdrop" variants={rot1}>
                <p className="top-values__quote-text">
                  {topLabel ?? ''}
                </p>
              </motion.div>
              <div className="top-values__quote-decoration--2" />
              <motion.div className="top-values__quote-attribution" variants={rot2}>
                {bottomLabel ?? ''}
              </motion.div>
            </motion.div>
          : null}
      </div>
    </div>
  )

export default TopValuesWithQuote
