import React from 'react'
import { Col, Row } from 'react-bootstrap'
import arrow from '~/assets/TWU_Arrow_1.png'
import './findings-intro.scss'

const FindingsIntro = ({ text }) => {
  const
    heading = text?.bannerHeading?.[0] ?? '',
    body = text?.bannerBody ?? []

  return (
    <Row className="findings-intro__row" style={{
      marginRight: '15px',
      marginLeft: '15px',
      marginTop: '0px',
      marginBottom: '25px'
    }}>
      <Col sm="12"  md="9" lg="9" xl="9" style={{
        paddingRight: '0px',
        paddingLeft: '0px'
      }}>
        <div className='findings-intro__outer'>
          <h1 className='findings-intro__heading'>
            <span className="findings-intro__heading-text">{heading}</span>
            <spam className="findings-intro__heading-highlight" />
          </h1>
          {body.map(t => <p key={t} className="findings-intro__body">{t}</p>)}
          <img className="findings-intro__arrow" src={arrow} alt="A hot pink, curved arrow pointing in the general direction of the map below" />
        </div>
      </Col>
    </Row>
  )
}

export default FindingsIntro
