import { useState, useEffect } from 'react'
import authenticateGoogleSpreadsheet from './authenticateGoogleSpreadsheet'
import { isEmpty } from 'lodash'
 
 const
  loadAndParse = async () => {
    const { doc, errorMessage } = await authenticateGoogleSpreadsheet()
    
    if (errorMessage) {
      return {
        isLoaded: true,
        errorMessage: errorMessage ?? 'Unable to load content'
      }
    }

    const
      bodyCopySheet = doc.sheetsById['1546374864'],
      bodyCopyRows = await bodyCopySheet.getRows(),
      text = {
        bannerHeading: bodyCopyRows.map(r => r.BannerHeading).filter(o => o),
        bannerBody: bodyCopyRows.map(r => r.BannerBody).filter(o => o),
        mapFooter: bodyCopyRows.map(r => r.MapFooter).filter(o => o),
        letterSalutation: bodyCopyRows.find(r => !isEmpty(r.LetterSalutation))?.LetterSalutation,
        letterClosing: bodyCopyRows.find(r => !isEmpty(r.LetterClosing))?.LetterClosing,
        letterLinkIntro: bodyCopyRows.find(r => !isEmpty(r.LetterLinkIntro))?.LetterLinkIntro,
        letterLink: bodyCopyRows.find(r => !isEmpty(r.LetterLink))?.LetterLink,
        letterLinkText: bodyCopyRows.find(r => !isEmpty(r.LetterLinkText))?.LetterLinkText,
        letterBody: bodyCopyRows.map(r => r.LetterBody).filter(o => o)
      }

    return {
      isLoaded: true,
      text,
      errorMessage: undefined
    }
  },
  CONTENT_KEY = 'TWU-text-content',
  useTextContent = () => {
    const
      localCopyJSON = localStorage.getItem(CONTENT_KEY),
      localCopy = localCopyJSON ? JSON.parse(localCopyJSON) : undefined,
      [state, setState] = useState(localCopy ?? {
        isLoaded: false,
        errorMessage: null,
        text: {}
      })

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newState = await loadAndParse()
  
          localStorage.setItem(CONTENT_KEY, JSON.stringify(newState))
  
          setState(oldState => ({...oldState, ...newState}))
        } catch (e) {
          setState(oldState => ({
            ...oldState,
            isLoaded: true,
            errorMessage: 'An unexpected error occurred while loading data'
          }))
        }
      }

      fetchData()
    }, [])
  
    return state
  }

export default useTextContent
