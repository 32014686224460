import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Marker, OverlayView } from 'react-google-maps'
import { mapIcons, FILTER_INCLUDE_ALL_KEY } from '~/utils/constants'
import './business-details-popover.scss'

const
  mapPaneName = OverlayView.OVERLAY_MOUSE_TARGET,
  isFilteredOut = ({name, town, industry}, {selectedName, selectedTown, selectedIndustry}) => {
    return (
      (selectedName && selectedName.length && (!name || !name.match(new RegExp(selectedName, 'gi')))) ||
      (selectedTown && selectedTown.length && selectedTown!==FILTER_INCLUDE_ALL_KEY && town!==selectedTown) ||
      (selectedIndustry && selectedIndustry.length && selectedIndustry!==FILTER_INCLUDE_ALL_KEY && industry!==selectedIndustry)
    )
  }

export const BusinessDetailsPopover = ({ position, name, town, industry, websiteUrl, filters, industryColors }) => {
  const
    [showOverlay, setShowOverlay] = useState(false),
    toggleOverlay = () => setShowOverlay(!showOverlay),
    isExcluded = isFilteredOut({name, town, industry}, filters),
    iconImage = mapIcons[industryColors[industry]] || mapIcons.DEFAULT,
    icon = {url: iconImage}

  if (isExcluded) {
    return null
  }

  return (
    <Container>
      <Marker
        onClick={toggleOverlay}
        icon={icon}
        position={position} />
      {showOverlay
        ? <OverlayView {...{ mapPaneName, position }}>
          <div className="details-popover__outer">
            <div className="details-popover__rect">
                {name || 'Name not provided'}
                {websiteUrl
                  ? <p>
                    <a href={websiteUrl} target="_blank" rel="noreferrer">
                      {websiteUrl}
                    </a>
                  </p>
                  : null}
            </div>
            <div className="details-popover__point" />
          </div>
        </OverlayView>
        : null}
    </Container>
  )
}

export default BusinessDetailsPopover
