import React from 'react'
import SimpleMetric from "./SimpleMetric"
import { AnnualContributionsMetric, AnnualContributorsMetric, BusinessAgeMetric, EarnedAnnuallyMetric, JobsCreatedMetric, OwnerIdentitiesMetric, ReadyToMentorMetric, SectionHeading, SerialEntrepreneursMetric } from './MetricsWidgets'
import StatsCloud from './StatsCloud'
import TopValuesWithQuote from './TopValuesWithQuote'
import DonutChart from './DonutChart'
import { times } from 'lodash'

const
  ReportMetric = ({
    id,
    type,
    className,
    simpleNumber,
    total,
    average,
    setSize,
    topLabel,
    bottomLabel,
    metricTitle,
    responseBreakdown,
    entranceOrderIndex,
    numberOfDecorations,
    numberOfColumns,
    numberOfRows,
    numExtraDecorations
  }) => {
    if (type === 'Simple Metric') {
      return (
        <SimpleMetric
          number={simpleNumber}
          {...{
            className,
            topLabel,
            bottomLabel,
            numberOfDecorations,
            entranceOrderIndex
          }}
        />
      )
    }

    if (type === 'Percentage Cloud') {
      return (
        <StatsCloud
          {...{ className, metricTitle, responseBreakdown }}
        />
      )
    }

    if (type === 'Response Grid') {
      return (
        <TopValuesWithQuote
          {...{ className, metricTitle, responseBreakdown, topLabel, bottomLabel, numberOfColumns, numberOfRows, numExtraDecorations }}
        />
      )
    }

    if (type === 'Donut Chart') {
      return (
        <DonutChart
          {...{ className, metricTitle, responseBreakdown }}
        />
      )
    }

    if (type === 'Response Row') {
      return <>
        {metricTitle ? <SectionHeading title={metricTitle} /> : null}
        {times(numberOfColumns).map((_, i) => (
          <SimpleMetric
            key={i}
            className={`${className} ${className}-${i + 1}`}
            number={`${responseBreakdown?.[i]?.percentage ?? ''}%`}
            bottomLabel={`"${responseBreakdown?.[i]?.quote ?? ''}"`}
            entranceOrderIndex={i + 1}
            {...{ numberOfDecorations }}
          />
        ))}
      </>
    }

    if (id === 'earnedAnnually') {
      return (
        <EarnedAnnuallyMetric
          totalEarnedAnnually={total}
          averageEarnedAnnuallyPerBusiness={average}
          numCompanies={setSize}
        />
      )
    }

    if (id === 'jobsCreated') {
      return (
        <JobsCreatedMetric
          totalJobsCreated={total}
          averagePerBusiness={average}
        />
      )
    }

    if (id === 'serialEntrepreneurs') {
      return <SerialEntrepreneursMetric
        totalSerialEntrepreneurs={setSize}
        {...{ topLabel, bottomLabel }}
      />
    }

    if (id === 'businessAge') {
      return <BusinessAgeMetric
        averageAgeOfBusiness={average}
        {...{ topLabel }}
      />
    }

    if (id === 'ownerIdentities') {
      return <OwnerIdentitiesMetric
        {...{ responseBreakdown, metricTitle }}
      />
    }

    if (id === 'annualContributions') {
      return <AnnualContributionsMetric
        donatedAnnually={total}
        donatedPerBusiness={average}
        {...{ topLabel, bottomLabel }}
      />
    }

    if (id === 'annualContributors') {
      return <AnnualContributorsMetric
        percentContributing={simpleNumber}
        {...{ topLabel, bottomLabel }}
      />
    }

    if (id === 'readyToMentor') {
      return <ReadyToMentorMetric
        numberReadyToMentor={simpleNumber}
        {...{ topLabel, bottomLabel }}
      />
    }

    return null
  }

export default ReportMetric
