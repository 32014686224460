import React from "react"
import TagManager from 'react-gtm-module'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, withRouter } from 'react-router-dom'
import App from '~/components/App'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

TagManager.initialize({ gtmId: "G-F20TXX48TF" })

const
  container = document.getElementById('root'),
  root = createRoot(container),
  AppWithRouter = withRouter(App)

// remove stale data on initial page load
localStorage.clear()

root.render(
  <BrowserRouter>{/* Basename for staging site only */}
    <AppWithRouter />
  </BrowserRouter>
)
