// animation "variations" for framer
// all animation states must have the same state names to be controlled
// by the parent motion div
export const
  scaleUpPulse = {
    rest: { rotate: -3 },
    hover: {
      rotate: 3,
      transition:{
        duration: .4,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  },
  scaleUpRound = {
    rest: {
      scale: 1,
      borderRadius: 0
    },
    hover: {
      scale: 1.1,
      borderRadius: 15
    }
  },
  scaleUp = {
    rest: { scale: 1 },
    hover: { scale: 1.1 }
  },
  rot1 = {
    rest: { rotate: 1 },
    hover: { rotate: -1 }
  },
  rot2 = {
    rest: { rotate: -1 },
    hover: { rotate: 1 }
  },
  swing1 = {
    rest: { x: 0 },
    hover: {
      x: 5,
      transition:{
        duration: .4,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  },
  swing2 = {
    rest: { x: 0 },
    hover: {
      x: -5,
      transition:{
        duration: .4,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  },
  swing3 = {
    rest: { y: 0 },
    hover: {
      y: 5,
      transition:{
        duration: .4,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  },
  swing4 = {
    rest: { y: 0 },
    hover: {
      y: -5,
      transition:{
        duration: .4,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  }
