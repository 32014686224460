import { useState, useEffect } from 'react'
import authenticateGoogleSpreadsheet from './authenticateGoogleSpreadsheet'
 
 const
  loadFAQData = async() => {
    const { doc, errorMessage } = await authenticateGoogleSpreadsheet()

    if (!doc) {
      return {
        isLoaded: true,
        errorMessage: errorMessage ?? 'Unable to load F.A.Q.s',
        faqData: []
      }
    }

    const
      faqSheet = doc.sheetsById['923218258'],
      faqRows = await faqSheet.getRows(),
      faqData = []

    faqRows.forEach((row, i) => {
      if (row && row.Question && row.Description) {
        faqData[i] = {
          question: row.Question,
          answer: row.Description
        }
      }
    })

    if (!faqData.length) {
      return {
        faqData: [],
        isLoaded: true,
        errorMessage: 'Loaded data succcessfully but no F.A.Q.s were found'
      }
    }

    return {
      faqData,
      isLoaded: true,
      errorMessage: null
    }
  },
  CONTENT_KEY = 'TWU-FAQ-content',
  useFAQContent = () => {
    const
      localCopyJSON = localStorage.getItem(CONTENT_KEY),
      localCopy = localCopyJSON ? JSON.parse(localCopyJSON) : undefined,
      [state, setState] = useState(localCopy ?? {
        isLoaded: false,
        errorMessage: null,
        faqData: []
      })

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newState = await loadFAQData()
  
          localStorage.setItem(CONTENT_KEY, JSON.stringify(newState))
  
          setState(oldState => ({...oldState, ...newState}))
        } catch (e) {
          setState(oldState => ({
            ...oldState,
            isLoaded: true,
            errorMessage: 'An unexpected error occurred while loading data'
          }))
        }
      }

      fetchData()
    }, [])
  
    return state
  }

export default useFAQContent
