import React from 'react'
import { motion } from 'framer-motion'
import { scaleUpRound, scaleUp, swing3, swing4 } from '~/utils/animationVariants'

import './stats-cloud.scss'

const
  commonAnimationProps = {
    initial: 'rest',
    whileHover: 'hover',
    whileTap: 'hover'
  },
  StatsCloud = ({ className, responseBreakdown, metricTitle }) => {
    const
      percentages = responseBreakdown?.map(({ percentage }) => percentage) ?? [],
      quotes = responseBreakdown?.map(({ quote }) => quote) ?? [],
      [state, setState] = React.useState({
        activeIndex: 0,
        activeColor: "#CD157D"
      }),
      { activeColor, activeIndex } = state,
      COLORS = [
        '#CD157D',
        '#F68C28',
        '#41C0E8',
        '#304FBC',
        '#F2CB00'
      ],
      setActiveOption = index => event => {
        if(responseBreakdown && event !== undefined){
          setState({
            activeIndex: index,
            activeColor: COLORS[index],
          })
        }
      }
    
    return (
      <div className={`stats-cloud ${className}`}>
        <div className="stats-cloud__container container">
          <motion.div
            className="stats-cloud__title"
            {...commonAnimationProps}
            variants={scaleUp}>
            {metricTitle ?? 'Loading...'}
          </motion.div>
          <div className="stats-cloud__character" />
          <div className="stats-cloud__cloud-wrapper">
            <div className="stats-cloud__backdrop" />
            {COLORS.map((color, i) => (
              <motion.div
                key={color}
                className={`stats-cloud__item stats-cloud__item--${i + 1}`}
                {...commonAnimationProps}
                onTap={setActiveOption(i)}
                onHoverStart={setActiveOption(i)}
                variants={scaleUpRound}>
                <p>
                  {percentages[i] ?? ''}%
                </p>
              </motion.div>
            ))}
          </div>
          <motion.div className="stats-cloud__detail" {...commonAnimationProps}>
            <motion.div
              className="stats-cloud__detail-bar"
              style={{background: activeColor}}
              variants={scaleUp}>
              <motion.div
                className="stats-cloud__detail-arrow"
                style={{ borderLeft: '15px solid ' + activeColor }}
                variants={scaleUp}
              />
            </motion.div>
            <div className="stats-cloud__detail-box">
              <motion.div
                className="stats-cloud__detail-quotemark"
                style={{color: activeColor}}
                variants={swing3}>
                “
              </motion.div>
              <p className="stats-cloud__detail-text stats-cloud__detail-quotemark--opening">
                {quotes[activeIndex] ?? ''}
              </p>
              <motion.div
                className="stats-cloud__detail-quotemark stats-cloud__detail-quotemark--closing"
                style={{color: activeColor}}
                variants={swing4}>
                “
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    )
  }

export default StatsCloud
