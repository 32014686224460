import * as React from 'react'
import { motion } from 'framer-motion'
import { Container } from 'react-bootstrap'
import useTextContent from '~/utils/useTextContent'
import { Link } from 'react-router-dom'
import signatureImg from '~/assets/front-page-signature.png'
import './home-page.scss'
import { isEmpty } from 'lodash'

const
  outerVariants = {
    hidden: { y: '-100vh' },
    visible: { y: 0, opacity: 1 },
    fadeOut: { y: '-100vh', opacity: 0}
  },
  outerTransition = {
    type: 'spring',
    duration: 1,
    bounce: 0
  },
  innerVariants = {
    rest: { scale: 0 },
    visible: (custom) => { // custom is the child number passed in to calculate the entrance delay
      return {
        scale: 1,
        transition: {
          delay: (custom * .4) + .1,
          duration: .4,
          type: 'spring',
          bounce: .4
        }
      }
    }
  },
  loadingAnimation = {
    animate: {
      opacity: [0.4, 1]
    },
    transition:{
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 0.25,
    }
  },
  HomePage = () => {
    const
      { text, errorMessage, isLoaded } = useTextContent(),
      hasContent = !isEmpty(text)

    return (
      <motion.div initial="hidden" animate="visible" exit="fadeOut" variants={outerVariants} transition={outerTransition}>
        <Container fluid style={{ marginBottom: '50px', minHeight: '1000px' }} className="home-page-content">
          <div className="topBackground"></div>
          <motion.div
            className="container"
            initial="rest"
            animate="visible"
            whileHover="hover" whileTap="hover">
            <motion.div
              className ="bodyContainer"
              custom={1}
              variants={innerVariants}
              initial="rest"
              animate="visible">
              <div className="bodyBox">
                <motion.div {...(isLoaded ? {} : loadingAnimation)} style={isLoaded ? {opacity: 1} : {}}>
                  <h1 className="highlighted">
                    <span className="highlight-text">{text.letterSalutation ?? (isLoaded ? '' : 'Loading...')}</span>
                    <span className='highlight-box' />
                  </h1>
                </motion.div>
                {isLoaded && hasContent
                  ? <>
                      {text.letterBody?.map(s => <p key={s}>
                        {s.split('**').map((subS, i) => i % 2 ? <b key={subS}>{subS}</b> : subS)}
                      </p>)}
                      <p>{text.letterLinkIntro} <a href={text.letterLink} className="orangeLink">{text.letterLinkText}</a>.</p>
                      <p>{text.letterClosing}<br/></p>
                      <img src={signatureImg} className="home-page-signature" alt="Signature of Meg A. Smith in thin black upright cursive letters" />
                      <p>Meg Smith<br/></p>
                      <Link
                        to="/full-data-report"
                        className="buttonBox">
                        Explore Results
                      </Link>
                    </>
                  : isLoaded
                    ? <p style={{ color: '#CD157D' }}>{errorMessage ?? 'Unable to load content.'}</p>
                    : null}
              </div>
            </motion.div>
          </motion.div>
        </Container>
      </motion.div>
    )
  }

export default HomePage
