import { GoogleSpreadsheet } from 'google-spreadsheet'
import credentials from './service-account-credentials.json'

/** Spreadsheet IDs
 * live survey: 1LoIR8TANIITX8HadvgGn05pBIU1aW4w3qQGb98gGvQ8
 * final results: 1dIhWWMKA2LxxITgUY9dIDpnu8IT8vL4rgQ9LN1pR2CM (beware: different formats)
 */
const
  doc = new GoogleSpreadsheet('1dIhWWMKA2LxxITgUY9dIDpnu8IT8vL4rgQ9LN1pR2CM'),
  authenticateGoogleSpreadsheet = async () => {
    try {
      await doc.useServiceAccountAuth(credentials);
      await doc.loadInfo();
      console.log('Google Sheet loaded successfully.')
      return {doc, errorMessage: null}

    } catch (e) {
      console.error('Creds could not be authorized.')
      return {doc: null, errorMessage: 'Could not load data (credentials could not be authorized)'}
    }
  }

export default authenticateGoogleSpreadsheet
