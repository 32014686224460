import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { LoaderRow } from '~/components/molecules'
import useFAQContent from '~/utils/useFAQContent'
import './faq-page.scss'

const
  FAQMessage = ({ message }) => {
    if (!message) { return null }

    return <Row
      className="justify-content-center"
      style={{
        margin: '20px',
        fontWeight: '500',
        fontSize: '25px'
      }}>
      {message}
    </Row>
  },
  scaleVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.05 }
  },
  FAQEntry = ({ question, answer }) => {
    return (
      <div className="faq-entry">
        <motion.div
          className="faqContainer"
          custom={0}
          variants={scaleVariants}
          initial="rest" animate="visible" whileHover="hover" whileTap="hover">
          <div className="questionBox" variants={scaleVariants}>{question}</div>
          <div className="answerBox" variants={scaleVariants}>
          {answer.split('\n').map((s, i) => (
            <p key={i}>{s}</p>
          ))}
          </div>            
        </motion.div>
      </div>
    )
  },
  FAQPage = () => {
    const { isLoaded, errorMessage, faqData } = useFAQContent()

    if (!isLoaded) {
      return (
        <Container fluid id="faq-page__loading">
          <LoaderRow text="Loading F.A.Q.s..." />
        </Container>
      )
    }

    if (errorMessage) {
      return <Container fluid id="faq-page__error">
        <Row className="justify-content-center" style={{ margin: '20px', fontWeight: '500', fontSize: '25px', color: '#CD157D' }}>
          {errorMessage}
        </Row>
      </Container>
    }

    return (
      <Container fluid className="faq-page__container">
        <Row className="justify-content-center" style={{'marginRight': '15px', 'marginLeft': '15px', 'marginTop':'25px', 'marginBottom':'25px', 'justifyContent':'center'}}>
          <Col style={{'justifyContent':'center'}}>                    
            {!isLoaded
              ? <FAQMessage message="Loading..." />
              : <FAQMessage message={errorMessage} />}
            {faqData.map(({ question, answer }) => (
              <FAQEntry key={question} {...{ question, answer }}></FAQEntry>
            ))}                    
          </Col>                
        </Row>
      </Container>
    )
  }

export default FAQPage
