import * as React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import {
  MainNav,
  Footer
} from '~/components/molecules/'
import { ScrollToTop } from '~/components/organisms'

import {
  HomePage,
  ReportPage,
  MapPage,
  FAQPage,
  NotFound
} from '~/components/pages'

const 
  App = () => {
    const location = useLocation()

    return (
      <div style={{height:'100%','background':'#f2f2f2'}}>
        <MainNav {...{ location }} />
        <div key="main" id="main">
          <ScrollToTop />
           {/* Any motion.div that enters the animate presence will be animated (enter/exit) */}
          <AnimatePresence key="animatepresence" exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={HomePage} />
              <Route path="/full-data-report" component={ReportPage} />
              <Route path="/map" component={MapPage} />
              <Route path="/faq" component={FAQPage} />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </AnimatePresence>
        </div>
        <Footer />
      </div>
    )
  }

export default App
