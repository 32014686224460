import React from 'react'
import { motion } from 'framer-motion'
import { VictoryPie } from 'victory'
import { scaleUp } from '~/utils/animationVariants'

import './donut-chart.scss'

const
  DonutChart = ({ className, responseBreakdown, metricTitle }) => {
    const
      COLORS = ["#CD157D", "#F68C28", "#F7E733", "#41C0E8", "#4676D3"],
      [state, setState] = React.useState({
        selectedPercentage: responseBreakdown ? `${responseBreakdown[1]?.percentage}%` : '',
        selectedColor: COLORS[1]
      }),
      { selectedPercentage, selectedColor } = state,
      onMouseOver = () => [
        {
          target: "data",
          mutation: ({ datum, style }) => {
            setState({
              selectedPercentage: (datum.y + "%"),
              selectedColor: style.fill
            })

            return {
              innerRadius: 75,
              radius: 145 - (datum.x * 10)
            }
          }
        }
      ],
      onMouseOut = () => [
        {
          target: "data",
          mutation: ({ scale }) => {
            return null;
          }
        }
      ],
      setActiveOption = index => event => {
        if(responseBreakdown && event !== undefined){
          const newVal = responseBreakdown ? `${responseBreakdown[index]?.percentage}%` : ''

          if (newVal !== selectedPercentage) {
            setState({
              selectedPercentage: newVal,
              selectedColor: COLORS[index]
            })
          }
        }
      }
    
    return (
      <div className={`donut-chart ${className}`}>
        <motion.div
          className="donut-chart__heading"
          initial="rest"
          whileHover="hover"
          whileTap="hover">
          <div className="donut-chart__heading-decoration--1" />
          <div className="donut-chart__heading-decoration--2" />
          <motion.div
            className="donut-chart__heading-text"
            variants={scaleUp}>
            {metricTitle}
          </motion.div>
        </motion.div>
        <div className="donut-chart__chart-outer container">
          <div className="donut-chart__chart-middle-wrapper">
            <div className="donut-chart__chart-inner">
              <div
                className="donut-chart__chart-label"
                style={{ color: selectedColor }}>
                {selectedPercentage}
              </div>
              <div className="donut-chart__chart-circle" />
              <div className="donut-chart__chart-proper">
                <VictoryPie
                  className="pie"
                  data={COLORS.map((_, i) => ({
                    x: i + 1,
                    y: responseBreakdown ? parseFloat(responseBreakdown[i]?.percentage) : 80,
                    label: ' '
                  }))}
                  colorScale={COLORS}
                  radius={({ datum }) => 140 - (datum.x * 10)}
                  innerRadius={80}
                  events={[{
                    target: "data",
                    eventHandlers: { onMouseOver, onMouseOut }
                  }]}
                />
              </div>
            </div>
            <div className="donut-chart__character--1"></div>
            <div className="donut-chart__chart-decoration--1"></div>
          </div>
          <div className="donut-chart__legend">
            {COLORS.map((_, i) => {
              const
                entry = responseBreakdown?.[i],
                stringValue = `${entry?.percentage}%`,
                isSelected = selectedPercentage === stringValue

              return <motion.div
                key={i}
                className="donut-chart__legend-entry"
                animate={isSelected ? 'hover' : 'rest'}
                onHoverStart={setActiveOption(i)}
                variants={scaleUp}>
                <div className={`donut-chart__legend-color donut-chart__legend-color--${i + 1} donut-chart__legend-color--${isSelected ? 'selected' : 'unselected'}`}></div>
                <div className="donut-chart__legend-backdrop">
                  <p className="donut-chart__legend-text">
                    {entry?.quote ?? ''}
                  </p>
                </div>
              </motion.div>
            })}
          </div>
        </div>
      </div>
    )
  }

export default DonutChart
