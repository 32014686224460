import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  GMLoadingElement,
  GMContainerElement,
  GMMapElement,
  GMErrorElement
} from '~/components/molecules'
import MapWithBusinesses from './MapWithBusinesses'
import './filtered-map.scss'

const
  FilteredMap = ({ activeFilters, className, children, isLoaded, errorMessage, industryColors, points }) => {
    return (
      <Row
        id="filtered-map__outer"
        className={className}>
        <Col
          sm="12"
          md="12"
          lg="10"
          xl="10"
          style={{
            paddingRight: '0px',
            paddingLeft: '0px'
          }}>
          <div className="filtered-map__inner">
            <div className="filtered-map__deco-box--yellow-left" />
            <div className="filtered-map__deco-box--yellow-right" />
            <div className="filtered-map__deco-box--orange-right" />
            {!isLoaded
              ? <GMLoadingElement />
              : errorMessage
                ? <GMErrorElement {...{errorMessage}} />
                : <MapWithBusinesses
                  points={points}
                  filters={activeFilters}
                  industryColors={industryColors}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAS07KS_tRxue6QGNEON8C_ddwTRB4A-r0"
                  loadingElement={GMLoadingElement()}
                  containerElement={GMContainerElement()}
                  mapElement={GMMapElement()}
                />}
          </div >
          {children}
        </Col>
      </Row>
    )
  }

export default FilteredMap
