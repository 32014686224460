import React from 'react'
import { motion } from 'framer-motion'
import { Row } from 'react-bootstrap'

const
  animationProps = {
    animate: {
      opacity: [0.4, 1]
    },
    transition:{
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 0.25,
    },
    variants: {
      rest: {
        rotate: 0
      },
      hover: {
        rotate: -3,
        transition: {
          duration: .3,
          repeat: Infinity,
          repeatType: "reverse"
        }
      }
    },
    initial: 'rest',
    whileHover: 'hover'
  },
  LoaderRow = ({ text }) => (
    <Row
      className="justify-content-center"
      style={{
        margin: '20px',
        fontWeight: '500',
        fontSize: '25px'
      }}>
      <motion.div
        style={{ opacity: 0.4 }}
        {...animationProps}
      >
        {text}
      </motion.div>
    </Row>
  )

export default LoaderRow
