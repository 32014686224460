import React, {} from 'react'
import greyMapStyles from './map-with-businesses-style.json'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'
import { BusinessDetailsPopover } from '~/components/molecules'

const 
  _MapWithBusinesses = ({ industryColors, filters, points }) => {
    return <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 44.075, lng: -72.662 }} // this centers on vermont
      defaultOptions={{
        styles: greyMapStyles
      }}
    >
      {points.map(({ lat, lng, name, websiteUrl, town, industry, key }, i) => (
        <BusinessDetailsPopover
          key={key}
          position={{lat: lat, lng: lng}}
          {...{name, town, industry, websiteUrl, filters, industryColors}}
        />
      ))}
    </GoogleMap>
  },
  MapWithBusinesses = withScriptjs(withGoogleMap(_MapWithBusinesses))

export default MapWithBusinesses
