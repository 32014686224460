import React, { forwardRef, useState, useEffect } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'
import { FILTER_INCLUDE_ALL_KEY } from '~/utils/constants'

export const MapFilter = ({ selected, options, setOption, isLoading }) => {
  const
    [optionFilter, setOptionFilter] = useState(''),
    [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 580),
    onSelect = (eventKey, event) => setOption(eventKey),
    filteredOptions = optionFilter.length
      ? options.filter(val => val.match(new RegExp(`^${optionFilter}`, 'i')))
      : options

  useEffect(() => {
    const checkSize = () => setIsSmallScreen(window.innerWidth <= 580)

    window.addEventListener("resize", checkSize)

    return () => { window.removeEventListener('resize', checkSize) }
  })

  // from https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
  // forwardRef because Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

      return (
        <div
          ref={ref}
          style={{...style, transform: `translate3d(0px, ${isSmallScreen ? 31 : 38}px, 0px)`}}
          className={`${className} hanging-menu`}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={isLoading ? 'Loading filter...' : 'Type to filter...'}
            onChange={(e) => setOptionFilter(e.target.value)}
            value={optionFilter}
          />
          <ul className="list-unstyled">
            {children}
          </ul>
        </div>
      )
    }
  )

  return (
    <div className="filter-select">
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle
          className="dropdown-toggle"
          variant="outline-secondary"
          id="dropdown-basic"
        >
          {selected}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu"
          as={CustomMenu}
          id="town-dropdown-menu"
        >
          <Dropdown.Item key={FILTER_INCLUDE_ALL_KEY} eventKey={FILTER_INCLUDE_ALL_KEY}>{FILTER_INCLUDE_ALL_KEY}</Dropdown.Item>
          <Dropdown.Divider/>
          {filteredOptions.map((name)=> (
            <Dropdown.Item key={name} eventKey={name}>{name}</Dropdown.Item>
           ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default MapFilter
