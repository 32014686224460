import { useState, useEffect } from 'react'
import authenticateGoogleSpreadsheet from './authenticateGoogleSpreadsheet'
import { isEmpty } from 'lodash'
 
 const
  formatDetailsById = {
    ownedBusinessesCounted: {
      className: 'metric--owned-businesses-counted',
      numberOfDecorations: 4,
      entranceOrderIndex: 1
    },
    majorityBusinessesCounted: {
      className: 'metric--majority-businesses-counted',
      numberOfDecorations: 3,
      entranceOrderIndex: 2
    },
    leadersCounted: {
      className: 'metric--leaders-counted',
      numberOfDecorations: 3,
      entranceOrderIndex: 3
    },
    startupFunds: {
      className: 'metric--startup-funds',
    },
    impetusMetric: {
      className: 'metric--impetus'
    },
    definingSuccess: {
      className: 'metric--defining-success'
    },
    startingChallenges: {
      className: 'metric--starting-challenges'
    },
    growingChallenges: {
      className: 'metric--growing-challenges'
    },
    adverseExperiences: {
      className: 'metric--adverse-experiences'
    },
    businessNeeds: {
      className: 'metric--business-need',
      numberOfDecorations: 3
    }
  },
  loadAndParse = async () => {
    const { doc, errorMessage } = await authenticateGoogleSpreadsheet()
    
    if (errorMessage) {
      return {
        isLoaded: true,
        errorMessage: errorMessage ?? 'Unable to load results'
      }
    }

    const
      bodyCopySheet = doc.sheetsById['1546374864'],
      bodyCopyRows = await bodyCopySheet.getRows(),
      text = {
        bannerHeading: bodyCopyRows.map(r => r.BannerHeading).filter(o => o),
        bannerBody: bodyCopyRows.map(r => r.BannerBody).filter(o => o),
        mapFooter: bodyCopyRows.map(r => r.MapFooter).filter(o => o),
        letterSalutation: bodyCopyRows.find(r => !isEmpty(r.LetterSalutation))?.LetterSalutation,
        letterClosing: bodyCopyRows.find(r => !isEmpty(r.LetterClosing))?.LetterClosing,
        letterLinkIntro: bodyCopyRows.find(r => !isEmpty(r.LetterLinkIntro))?.LetterLinkIntro,
        letterLink: bodyCopyRows.find(r => !isEmpty(r.LetterLink))?.LetterLink,
        letterLinkText: bodyCopyRows.find(r => !isEmpty(r.LetterLinkText))?.LetterLinkText,
        letterBody: bodyCopyRows.map(r => r.LetterBody).filter(o => o)
      },
      staticResultsSheet = doc.sheetsById['231749440'],
      staticResultsRows = await staticResultsSheet.getRows(),
      results = staticResultsRows.map(row => ({
        id: row.ID,
        type: row.Type,
        location: row.Location,
        simpleNumber: row.SoleValue,
        topLabel: row.TopLabel,
        bottomLabel: row.BottomLabel,
        total: row.Total,
        average: row.Average,
        setSize: row.SetSize,
        metricTitle: row.MetricTitle,
        responseBreakdown: staticResultsRows.filter(r => r.ID === row.ID).map(r => ({
          percentage: r.ResponsePercent,
          count: r.ResponseCount,
          quote: r.ResponseText
        })),
        numberOfRows: row.Rows,
        numberOfColumns: row.Columns,
        numExtraDecorations: row.ExtraDecorations,
        highlightedQuote: row.HighlightedQuote,
        highlightedSpeaker: row.HighlightedSpeaker,
        ...(formatDetailsById[row.ID] ?? {})
      })).filter(o => o.type)

    return {
      isLoaded: true,
      results,
      text,
      errorMessage: undefined
    }
  },
  useResultsContent = () => {
    const
      localCopyJSON = localStorage.getItem('TWU-results-content'),
      localCopy = localCopyJSON ? JSON.parse(localCopyJSON) : undefined,
      [state, setState] = useState(localCopy ?? {
        isLoaded: false,
        errorMessage: undefined,
        results: [],
        text: {}
      })
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const parsedData = await loadAndParse()
  
          localStorage.setItem('TWU-results-content', JSON.stringify(parsedData))
  
          setState(oldState => ({ ...oldState, ...parsedData }))
        } catch(e) {
          setState(oldState => ({
            ...oldState,
            isLoaded: true,
            errorMessage: 'An unexpected error occurred while loading data'
          }))
        }
      }

      fetchData()
    }, [])
  
    return state
  }

export default useResultsContent
