import * as React from 'react'
import './footer.scss'

const
  PRIVACY_POLICY_URL = 'https://docs.google.com/document/d/1-xz50a3fTna7IhFV-hstM_8DIZ_J5See',
  TERMS_URL = 'https://docs.google.com/document/d/1uQl2BTFvXXNNK3GGRH6zcn8YyzsmVd_C',
  TERMS_AGREEMENT_TEXT = 'Please read the Policy carefully to understand our policies and practices regarding your information and how we will treat it.  If you do not agree with the policies and practices detailed herein, you must discontinue your use of our Website and Application immediately.  By continuing to use our Website and Application you consent to the terms of this Policy.',
  footerNavLinks = [
    // { label: 'Get in Touch', path: 'mailto:hello@thiswayupvt.com' },
    { label: 'Privacy policy', path: PRIVACY_POLICY_URL },
    { label: 'Terms & Conditions', path: TERMS_URL }
  ],
  attribution = <p>
    Designed & Built by{' '}
    <a
      className="orangeLink"
      href="https://www.gametheoryco.com/"
      target="_blank"
      rel="noreferrer">
      GameTheoryCo
    </a>
  </p>,
  OrangeLink = ({ path, label, }) => (
    <a
      href={path}
      className="orangeLink"
      target="_blank"
      rel="noreferrer"
    >
      {label}
    </a>
  ),
  Footer = () => {
    return <div className="footer">
      <div className="footerRight">
        <div className="logoSmall"></div>
        {attribution}
      </div>
      <div className="footerMid">
        {footerNavLinks.map(({ path, label }, i) => (
          <p key={i}><OrangeLink {...{ path, label }} /></p>
        ))}
      </div>
      <div className="footerLeft">
        <p>
          All information we collect on this Website is subject to our{' '}
          <OrangeLink path={PRIVACY_POLICY_URL} label="Privacy Policy" />{' '}
          and{' '}
          <OrangeLink path={TERMS_URL} label="Terms & Conditions of Use"/>.{' '}
          {TERMS_AGREEMENT_TEXT}
        </p>
        <p>
          This website is operated by the Vermont Women's Fund, in affiliation with the Vermont Community Foundation.
        </p>
      </div>
    </div>
  }

export default Footer
