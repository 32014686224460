import { useState, useEffect } from 'react'
import authenticateGoogleSpreadsheet from './authenticateGoogleSpreadsheet'
import { isEmpty } from 'lodash'
 
 const
  loadMapData = async () => {
    const
      towns = [],
      points = [],
      pointKeys = [],
      industries = [],
      industryColors = {},
      { doc, errorMessage } = await authenticateGoogleSpreadsheet()

    if (!doc) { 
      return {
        isLoaded: true,
        errorMessage: errorMessage ?? 'Unable to load map',
        mapData: { towns, industries, industryColors, points }
      }
    }

    const
      contactsSheet = doc.sheetsById["766403640"],
      contactsRows = await contactsSheet.getRows(),
      industrySheet = doc.sheetsById["1553717988"],
      industryRows = await industrySheet.getRows()

    contactsRows.forEach(row => {
      if (!row || isEmpty(row.lat) || isEmpty(row.lng) || isNaN(parseFloat(row.lat)) || isNaN(parseFloat(row.lng))) { return }

      if (row.Town && towns.indexOf(row.Town)<0) {
        towns.push(row.Town)
      }

      const
        key = `${row.name}-${row.lat}-${row.lng}`,
        newPoint = {
          lat: parseFloat(row.lat),
          lng: parseFloat(row.lng), 
          name: row.Name,
          websiteUrl: row.Website,
          town: row.Town,
          age: row.Age,
          size: row.Size,
          industry: row.Industry,
          key
        },
        indexOfExistingPointForBusiness = pointKeys.indexOf(key)

      if (indexOfExistingPointForBusiness < 0) {
        pointKeys.push(key)
        points.push(newPoint)
      } else {
        points[indexOfExistingPointForBusiness] = newPoint
      }
    })

    industryRows.forEach(row => {
      if (row && row.Industry) {
        industries.push(row.Industry)
        industryColors[row.Industry] = row["Hex Code"]
      }
    })

    if (!points.length) {
      return {
        mapData: { towns, industries, industryColors, points },
        isLoaded: true,
        errorMessage: 'Loaded data succcessfully but no businesses were found'
      }
    }

    return {
      towns: towns.sort(),
      industries: industries.sort(),
      industryColors: industryColors,
      points,
      isLoaded: true,
      errorMessage: null
    }
  },
  CONTENT_KEY = 'TWU-map-content',
  useMapContent = () => {
    const
      localCopyJSON = localStorage.getItem(CONTENT_KEY),
      localCopy = localCopyJSON ? JSON.parse(localCopyJSON) : undefined,
      [state, setState] = useState(localCopy ?? {
        isLoaded: false,
        errorMessage: null,
        mapData: {}
      })

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newState = await loadMapData()
  
          localStorage.setItem(CONTENT_KEY, JSON.stringify(newState))
  
          setState(oldState => ({...oldState, ...newState}))
        } catch (e) {
          setState(oldState => ({
            ...oldState,
            isLoaded: true,
            errorMessage: 'An unexpected error occurred while loading data'
          }))
        }
      }

      fetchData()
    }, [])
  
    return state
  }

export default useMapContent
